$stroke-size: 136px;

.w__container {
    display: block;
    position: relative;

    min-height: $stroke-size * 1.5;

    .stroke {
        position: absolute;

        &__1 {
            top: $stroke-size;
            left: 0;

            .line__1 {
                position: absolute;
                top: 0;
                left: 0;
                height: 1px;
                width: $stroke-size;
                background-color: black;

                transform: rotateZ(75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * 0.485;
                left: $stroke-size * 0.625;
                height: 1px;
                width: $stroke-size * 0.325;
                background-color: black;
            }

            transition: transform 500ms ease;
        }

        &__2 {
            top: $stroke-size;
            left: 0;

            .line__1 {
                position: absolute;
                top: $stroke-size * -0.125;
                left: $stroke-size * 0.425;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * -0.485;
                left: $stroke-size * 0.45;
                height: 1px;
                width: $stroke-size * 0.25;
                background-color: black;
            }

            transform: rotateZ(30deg) translate3d($stroke-size * 0.175, $stroke-size * -0.3, 0);

            transition: transform 500ms ease;
        }

        &__3 {
            top: $stroke-size;
            left: 0;

            .line__1 {
                position: absolute;
                top: $stroke-size * -0.1;
                left: $stroke-size * 0.3;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(-75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * -0.475;
                left: $stroke-size * 0.775;
                height: 1px;
                width: $stroke-size * 0.25;
                background-color: black;
            }

            .line__3 {
                position: absolute;
                top: $stroke-size * -0.1;
                left: $stroke-size * 0.75;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(75deg);
            }

            transition: transform 500ms ease;
        }

        &__4 {
            top: $stroke-size;
            left: $stroke-size * 0.75;

            .line__1 {
                position: absolute;
                top: $stroke-size * 0.125;
                left: $stroke-size * 0.3;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(-75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * 0.125;
                left: $stroke-size * 0.5;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(75deg);
            }

            transition: transform 500ms ease;
        }

        &__5 {
            top: $stroke-size;
            left: $stroke-size * 0.75;

            .line__1 {
                position: absolute;
                top: 0;
                left: $stroke-size * 0.925;
                height: 1px;
                width: $stroke-size;
                background-color: black;

                transform: rotateZ(-75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * 0.485;
                left: $stroke-size * 0.975;
                height: 1px;
                width: $stroke-size * 0.325;
                background-color: black;
            }

            transition: transform 500ms ease;
        }

        &__6 {
            top: $stroke-size;
            left: $stroke-size * 0.75;

            .line__1 {
                position: absolute;
                top: $stroke-size * -0.125;
                left: $stroke-size * 0.425;
                height: 1px;
                width: $stroke-size * 0.75;
                background-color: black;

                transform: rotateZ(-75deg);
            }

            .line__2 {
                position: absolute;
                top: $stroke-size * -0.485;
                left: $stroke-size * 0.9;
                height: 1px;
                width: $stroke-size * 0.25;
                background-color: black;
            }

            transform: rotateZ(-5deg) translate3d($stroke-size * 0.175, 0, 0);

            transition: transform 500ms ease;
        }
    }

    &:hover {
        $animation-offset: $stroke-size * 0.25;

        .stroke {
            &__1 {
                transform: translate3d($animation-offset, 0, 0);
            }

            &__2 {
                transform: rotate(0) translate3d(($stroke-size * -0.075 + $animation-offset), 0, 0);
            }

            &__3 {
                transform: rotate(0) translate3d(($stroke-size * 0.25 + $animation-offset), 0, 0);
            }

            &__4 {
                transform: rotate(0) translate3d(($stroke-size * 0.125 + ($animation-offset * -1)), 0, 0);
            }

            &__5 {
                transform: rotate(0) translate3d(($stroke-size * 0.125 + ($animation-offset * -1)), 0, 0);
            }

            &__6 {
                transform: rotate(0) translate3d(($stroke-size * 0.525 + ($animation-offset * -1)), 0, 0);
            }
        }
    }
}