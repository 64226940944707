$nav-height: 110px; // nav + cintilla

.main-container {
    min-height: calc(100vh - $nav-height);
    width: 100%;

    .video {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .hero-content {
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        color: #f1f1f1;
        width: 100%;
        height: 100%;

        .center-container {
            min-height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
                max-width: 512px;
            }

            h4 {
                font-size: 24px;
            }

            @media (max-width: 768px) {
                img {
                    max-width: 300px;
                }

                h4 {
                    font-size: medium;
                }
            }
        }

        .hero-footer {
            min-height: 100px;
            position: absolute;
            bottom: 0;
            width: 100%;

            .social-container {
                display: none;

                @media (min-width: 768px) {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }

            .scroll-icon {
                animation: bounce 1s infinite;
            }

            .play-button {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                margin-right: 2rem;
                font-family: 'Arial black', sans-serif;

                background-color: transparent;
                color: white;
                border: 1px solid white;
            }
        }
    }

    .videoDesktop {
        display: flex;
        width: 100%;
    }

    .videoMobile {
        display: none;
    }

    @media screen and (max-width: 768px) {
        .videoDesktop {
            display: none;
        }

        .videoMobile {
            display: flex;
            width: 100%;
        }
    }

    @keyframes bounce {
        0% {
            transform: translateY(0px);
        }

        50% {
            transform: translateY(8px);
        }

        100% {
            transform: translateY(0px);
        }
    }
}