$orange: #FF7700;

.info-slide {
    height: 366px;
    overflow: hidden;

    padding: 3rem 2rem;
    margin: 0.5rem;

    border-width: 4px;
    border-style: solid;
    border-color: $orange;

    @media (min-width: 768px) {
        height: 304px;
    }
}

.hover-orange:hover {
    color: $orange;
}