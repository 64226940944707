$spacer: 1.75rem;
$project-height: 256px;
$project-min-width: $project-height * 1.5;

.projects-grid {
    display: grid;
    padding: $spacer;
    gap: $spacer;
    grid-template-areas:
        "a a a b b"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "a a a d d"
        "c c c d d";

    .project {
        background-color: black;
        min-height: $project-height;

        &-1 {
            grid-area: a;
        }

        &-2 {
            grid-area: b;
            min-width: $project-min-width;
        }

        &-3 {
            grid-area: c;
        }

        &-4 {
            grid-area: d;
            min-width: $project-min-width;
        }

        &.with-overlay {
            position: relative;

            &:hover:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: black;
                opacity: 0.25;
                cursor: pointer;
            }
        }
    }

    @media (max-width: 768px) {
        grid-template-areas:
            "a a"
            "b d"
            "c c";

        padding: 1rem;
        gap: 0.5rem;

        .project {

            &-1 {
                min-width: 64px;
            }

            &-2 {
                min-width: 64px;
            }

            &-3 {
                min-width: 64px;
            }

            &-4 {
                min-width: 64px;
            }
        }

        h2 {
            font-size: x-large;
        }
    }
}