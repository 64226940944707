.cintilla-container {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 64px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    
    transition: 1s;
    
    @media (min-width: 768px) {
        height: 48px;
    }
}