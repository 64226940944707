.diagram {
    display: none;

    @media (min-width: 768px) {
        display: block;
        width: 100%;
        height: auto;

        transform: translateY(-1.75rem);
    }

}

.diagram__mobile {
    display: block;
    width: 100%;
    height: auto;

    transform: translateY(-1.75rem);

    @media (min-width: 768px) {
        display: none;
    }

}

.message {
    font-size: 12px;

    @media (min-width: 768px) {
        font-size: large;
    }
}