$orange: #FF7700;
$transition-duration: 500ms;

.header-contacto {
    font-size: 2.5rem;
}

@media (min-width: 768px) {
    .header-contacto {
        font-size: 5rem;
    }

    .d-padding {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.decoration__container {
    $square-size: 57px;
    display: block;
    position: relative;

    .square__one {
        position: absolute;
        top: 0;
        right: 0;
        width: $square-size;
        height: $square-size;
        background-color: transparent;
        border: 2px solid black;
        transform: translateX($square-size / -2) translateY($square-size) rotate(15deg);

        transition: transform $transition-duration ease;
    }

    .square__two {
        position: absolute;
        top: $square-size;
        right: $square-size;
        width: $square-size;
        height: $square-size;
        background-color: transparent;
        border: 2px solid black;
        transform: translateX($square-size / -1.25) translateY($square-size * -0.5) rotate(-15deg);

        transition: transform $transition-duration ease;
    }

    .intersection {
        position: absolute;
        top: $square-size;
        right: $square-size * 1.25;
        background-color: $orange;
        opacity: 0;
        width: $square-size / 4;
        height: $square-size / 2;
        transition: opacity ($transition-duration * 2) ease;
    }

    &:hover {
        .square__one {
            transform: translateX($square-size / -2) translateY($square-size) rotate(0deg);
        }

        .square__two {
            transform: translateX($square-size / -4) translateY($square-size * -0.5) rotate(0);
        }

        .intersection {
            opacity: 1;
        }
    }

    .message__container {
        margin-top: $square-size * 0.75;
        font-size: 1rem;
    }

    @media (min-width: 768px) {
        $square-size: 180px;
        display: block;
        position: relative;
        min-height: 396px;

        .square__one {
            top: auto;
            bottom: 0;
            width: $square-size;
            height: $square-size;
            transform: translateX($square-size / -4) translateY($square-size / -4) rotate(15deg);
        }

        .square__two {
            top: auto;
            bottom: $square-size;
            right: $square-size;
            width: $square-size;
            height: $square-size;
            transform: translateX($square-size / -2.5) translateY($square-size / 4) rotate(-15deg);
        }

        .intersection {
            position: absolute;
            top: auto;
            bottom: $square-size * 0.75;
            right: $square-size;
            width: $square-size / 4;
            height: $square-size / 2;
        }

        &:hover {
            .square__one {
                transform: translateX($square-size / -4) translateY($square-size / -4) rotate(0deg);
            }

            .square__two {
                transform: translateX(0) translateY($square-size / 4) rotate(0);
            }
        }

        .message__container {
            margin-top: $square-size * 0.75;
            padding-right: 10rem;
        }
    }
}

.title__container {
    position: relative;
    z-index: 1;
}

.photo__container {
    position: relative;
    z-index: 0;

    .photo {
        margin-top: 1rem;
        width: 100%;
        height: auto;
    }

    @media (min-width: 768px) {
        position: absolute;
        top: 4rem;
        left: 10rem;
        width: 256px;
        height: auto;

        .photo {
            width: 120%;
            margin-top: 2.5rem;
        }
    }
}

.input__group {
    margin-bottom: 1.5rem;
}

input.form__input {
    width: 100%;

    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;

    color: black;
    background-color: transparent;

    padding: 0.5rem 1rem;
    margin: 0.75rem 0;

    &:focus:valid {
        outline-color: green;
        border-color: green;
    }

    &:focus:optional {
        border-color: black;
        outline-color: black;
    }

    &:focus:invalid {
        border-color: red;
        outline-color: red;
    }

    &:focus {
        outline-color: black;
        border-color: black;
    }

    @media (min-width: 768px) {
        margin-top: 1.5rem;
        margin-bottom: 0px;
        margin-top: 0px;
        margin-top: 0px;
    }
}

.ng-desktop {
    margin-top: 8rem;
    font-size: 1.2rem;
    padding-right: 8rem;

    @media (max-width: 768px) {
        margin-top: 1rem;
        font-size: 1rem;
    }
}

.input-error {
    color: red;
    margin: 0;
    padding: 0;
    padding-left: 1rem;
}