$slide-size: 684px;

.main__container {
    position: relative;
    min-height: 512px;
    padding: 1rem;
    margin-top: 32px;

    .bg__upper {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        background: url("../../../public/img/home/testimonios/textura.jpg") no-repeat center center/cover;
        width: 100%;
        height: 30%;
    }

    .bg__lower {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: #e1e1e1;
        width: 100%;
        height: 70%;
    }

    .carousel__container {
        position: relative;
        z-index: 1;
        display: block;
        margin: auto;
        width: 100%;
        max-width: 896px;

        .custom__slide {
            display: flex;
            flex-direction: column-reverse;
            height: $slide-size;

            .text__container {
                background-color: white;
                padding: 2rem;
                height: $slide-size / 2;

                .logo {
                    display: none;
                }
            }

            .img__container {
                position: relative;
                height: $slide-size / 2;

                .photo {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .overlay {
                    position: absolute;
                    left: 0;
                    top: 0;

                    width: 100%;
                    height: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background-color: rgba($color: #000000, $alpha: 0.25);

                    .overlay__logo {
                        width: 5rem;
                        height: 5rem;
                        object-fit: contain;
                        background-color: transparent;
                    }
                }
            }

        }
    }

    @media (min-width:768px) {
        $slide-size: 420px;

        position: relative;
        min-height: 512px;
        padding: 1.5rem;

        .bg__upper {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background: url("../../../public/img/home/testimonios/textura.jpg") no-repeat center center/cover;
            width: 100%;
            height: 80%;
        }

        .bg__lower {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 0;
            background: #e1e1e1;
            width: 100%;
            height: 20%;
        }

        .carousel__container {
            position: relative;
            z-index: 1;
            display: block;
            margin: auto;
            width: 100%;
            max-width: 896px;

            .custom__slide {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                height: $slide-size;

                .img__container {
                    height: $slide-size;
                }

                .text__container {
                    flex: 1;
                    background-color: white;
                    padding: 2rem;
                    height: $slide-size;

                    .logo {
                        display: flex;
                        margin: 0.5rem auto;
                        width: auto;
                        height: 8rem;
                        //object-fit: contain;
                    }
                }

                .img__container {
                    flex: 1;

                    .photo {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .overlay {
                        display: none;
                    }
                }

            }
        }
    }
}

.prev__button {
    position: absolute;
    left: 30%;
    bottom: -100%;

    img.icon {
        width: 1.5rem;
        content: url("../../../public/svg/black-arrow-left.svg");
    }

    @media (min-width: 768px) {
        left: -6rem;
        bottom: 0;

        img.icon {
            width: 2rem;
            content: url("../../../public/svg/white-arrow-left.svg");
        }
    }
}

.next__button {
    position: absolute;
    right: 30%;
    bottom: -100%;

    img.icon {
        width: 1.5rem;
        content: url("../../../public/svg/black-arrow-right.svg");
    }

    @media (min-width: 768px) {
        right: -6rem;
        bottom: 0;

        img.icon {
            width: 2rem;
            content: url("../../../public/svg/white-arrow-right.svg");
        }
    }
}